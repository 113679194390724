.add-block-menu {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 25px;
  width: 20px;
}

.add-block-menu:hover {
  background: var(--gray2);
}
