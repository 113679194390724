$bg-color-dark: #3d3d3d !important;
$color-white: #f3f3f3 !important;
$border-radius: 5px !important;

.video-container {
  position: relative;
  background-color: var(--gray19);
  height: 100%;
  width: 100%;

  button {
    background-position: center;
    touch-action: manipulation;
    transition: background 0.1s;
  }

  .video-logo-header {
    border-radius: $border-radius;
    padding: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;

    img {
      height: 32px;
    }
  }

  .video-view-controller-container {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 1;
    gap: 8px;
    display: flex;

    .video-view-controller-button {
      width: 32px;
      height: 32px;
      background-color: var(--gray15);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: var(--gray13);
      }
    }
  }

  .video-placeholder-container {
    position: relative;
    height: 90%;
    display: flex;
    align-items: center;

    .recoding-red-icon {
      position: absolute;
      top: -35px;
      left: 7px;
      width: 25px;
    }

    .recoding-red-icon-hide {
      display: none;
    }

    .video-placeholder {
      position: relative;
      background-color: $bg-color-dark;
      width: 100%;

      // to keep 16:9 ratio
      padding-top: 56.25%;

      &.vertical {
        margin-bottom: 0;
      }

      .video-player {
        position: absolute;
        left: 0;
        top: 0;
        height: auto !important;
        aspect-ratio: 16/9;

        border: unset;

        video {
          outline: none;
        }
      }
    }
  }

  .video-play-controller-container {
    background-color: #191c20;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 72px;
    padding: 14px 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    .setting-container {
      .setting-toggle {
        position: absolute;
        left: 16px;
        top: 14px;
        display: flex;
        align-items: center;
        padding: 8px;
        cursor: pointer;
        border-radius: 8px;

        &:hover {
          background-color: var(--gray13);
        }

        .setting-icon {
          margin-right: 2px;
        }
      }

      .setting-popup {
        width: 264px;
        border-radius: $border-radius;
        padding: 12px 16px;

        .setting-title {
          font-size: 1.6rem;
          font-weight: 700;
        }

        .setting-option {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 1.4rem;
          .select {
            width: 77px;
          }
        }

        .setting-option:nth-of-type(2) {
          margin-top: 8px;
        }
      }
      .vnote-video-setting {
        transform: translate3d(16px, -16px, 0px) !important;
      }
    }

    .control-container {
      display: flex;
      .play-btn-clicked {
        width: 44px;
        height: 44px;
        margin-left: 12px;
        margin-right: 12px;
        padding: 0;
        background-color: var(--gray4);
        border-radius: 8px;
        cursor: pointer;
        border: none;

        &:focus {
          outline: 0;
          border: none;
        }
      }

      .play-btn {
        width: 44px;
        height: 44px;
        margin-left: 12px;
        margin-right: 12px;
        padding: 0;
        background-color: var(--gray1);
        border-radius: 8px;
        cursor: pointer;
        border: none;

        &:hover {
          background-color: var(--gray4);
        }
        &:focus {
          outline: 0;
          border: none;
        }
      }

      .skip-btn-clicked {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        width: 72px;
        background-color: var(--gray9);
        border-radius: 8px;
        border: 0;
        padding: 0;
        cursor: pointer;
        &:focus {
          outline: 0;
          border: none;
        }

        .skip-backward-icon {
          margin-left: 3px;
          width: 28px;
        }
        .skip-forward-icon {
          margin-right: 3px;
          width: 28px;
        }
      }

      .skip-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        width: 72px;
        background-color: var(--gray15);
        border-radius: 8px;
        border: 0;
        padding: 0;
        cursor: pointer;

        &:focus {
          outline: 0;
          border: none;
        }

        &:hover {
          background-color: var(--gray13);
        }

        .skip-backward-icon {
          margin-left: 3px;
          width: 28px;
        }
        .skip-forward-icon {
          margin-right: 3px;
          width: 28px;
        }
      }
    }

    .video-transcript-toggle-button-wrapper {
      position: absolute;
      right: 16px;
    }

    .select-controller-container {
      display: flex;
      position: absolute;
      right: 19px;

      .skip-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        width: 44px;
        background-color: var(--gray15);
        border-radius: 8px;
        border: 0;
        padding: 0;
        cursor: pointer;

        &:hover {
          background-color: var(--gray13);
        }

        &:focus {
          outline: 0;
          border: none;
        }

        &:disabled {
          cursor: default;

          &:hover {
            background-color: var(--gray13);
          }
        }
      }
      .skip-btn:first-of-type {
        margin-right: 12px;
      }
    }

    #showCurrentSpeedToastDiv {
      position: absolute;
    }
  }
}

.mobile {
  height: fit-content;

  .video-placeholder-container {
    align-items: flex-start;
    .video-placeholder {
      margin-bottom: 50px;
    }
  }

  .video-play-controller-container {
    height: 50px;

    .control-container {
      .skip-btn {
        font-size: 1.4rem;
        .video-icon {
          width: 20px;
          height: auto;
        }
      }
      .skip-btn,
      .play-btn {
        height: 35px;
      }
      .play-btn {
        width: 35px;
      }
    }
  }
}
